import { Link, navigate } from "gatsby"
import React from "react"
import { Button } from 'react-bootstrap'

import Container from 'react-bootstrap/Container'
import { bdstatistics, check, mqStatistics } from '../components/census'
import Footer from '../components/footer'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "./../components/header"
import MarketCases from './../components/MarketCases'
import MobileHeader from "./../components/MobileHeader"

import './../assets/css/aritcle.css'
import './../assets/css/aritclesDetail.css'
import './../assets/css/index.css'
import './../components/media.css'
import './../components/release.css'

export default () => {
  let [ active, setActive ] = React.useState(0)
  let [ hashActive, setHashActive ] = React.useState(null)
  const [agent, setAgent] = React.useState(true)
  const [ width, setWidth ] = React.useState(false)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    setAgent(check())
  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('common-flex');
      if(hashActive) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == hashActive) {
            window.scrollTo({
              top: product_media[i].offsetTop -120
            })
          }
        }
      }
    }
  }, [hashActive])

  const getHeader = (width) => {
    return width ? <div style={width ? { display: 'block'} : {display: 'none'}}>
      <MobileHeader siteTitle="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
    </div> : 
    <div style={width ? { display: 'none'} : {display: 'block'}}><Header siteTitle="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" /></div>
  }

  const setRootFontSize = () => {
    let width = document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 1200) {
      width = 1600;
    }
    let fontSize = (width / 100) >= 12 ? (width/100) : 12
    document.getElementsByTagName('html')[0].style['fontSize'] = fontSize + 'px'
  }

  //全局设置页面文字大小
  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let width = document.body.clientWidth
      if(width < 992) {
        setWidth(true)
      }else {
        setWidth(false)
      }
      window.addEventListener('resize', (event) => {
        setRootFontSize()

        let width = document.body.clientWidth
        if(width < 992) {
          setWidth(true)
        }else {
          setWidth(false)
        }

      })
    }
    setRootFontSize()
  }, [])
  
  const isMobile = !check()
  
  return (
    <>
      <div style={{ display: 'block', height: 'auto' }}>
        <div className="home-index home-nav-fix">
          <Container style={isMobile ? { padding: 0, marginBottom: '12rem' }: { marginBottom: '12rem' } }>
            <div className="home-swiper" style={{ paddingTop: '4.5rem' }}>
              <div className="engine-home-4 tg-index-page" style={{ display: 'flex', textAlign: 'center' }}>
                <div className="home-index home-tg home-pref" style={{ position: 'relative', height: '100%', margin: '0 auto' }}>
                  <div className="jumbotron-title-common" style={{ display: 'block', fontSize: agent ? '2.8rem' : '2rem', color: 'rgba(18, 20, 28, 0.9)' }}>
                  平台预制<span className="zhxt zhxt-sptcail">40+</span>行业案例模板
                  </div>
                  <div className="jumbotron-title-common" style={{ marginTop: 20, display: 'block', fontSize: agent ? '2.8rem' : '2rem', color: 'rgba(18, 20, 28, 0.9)' }}>
                  平均缩短项目实施周期<span className="zhxt zhxt-sptcail">70%</span>节省<span className="zhxt zhxt-sptcail">50%</span>人力成本
                  </div>
                  <div className="jumbotron-title-common"  style={{ marginTop: 20, display: 'block', fontSize: agent ? '2.8rem' : '2rem', color: 'rgba(18, 20, 28, 0.9)' }}>高效交付智慧项目</div>
                  <div>
                    <Button variant="primary" className="home-pref-btn" onClick={() => navigate("/engine4")}>立即体验</Button>
                    <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank"><Button variant="primary" className="home-pref-btn" style={{ marginLeft: 56 }} >申请试用</Button></Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Layout>
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
          </Layout>
        </div>
      </div>
      <Container> 
        {/* <StaticQueryCommon type="articles" active={active} changeCommom={ (index, item) => { setActive(index); setHashActive(item) }}/> */}
        <MarketCases  type="articles" />
      </Container>
      <div style={{ margin: '2rem 0 5rem' }}>
        <a style={{ display: 'inline-block', width: '100%', height: '100%' }} target="_blank" href="https://appxow65u356815.pc.xiaoe-tech.com/all/8028604?from_tag=1">
          <img src={require('./../images/WechatIMG1252.jpg')} style={{ width: '100%', height: '100%' }} />
        </a>
      </div>  
    <Footer></Footer>
  </>
  )
}

  